import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import { THEMES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
      color: "#bababa"
    } : {}
  },
  toolbar: {
    minHeight: 64
  }
}));


const AppIcon = (props) => {
  const classes = useStyles();

  const source = classes.root.boxShadow === 'none' ? "/static/getAppDark.png" : "/static/getAppDark.png"

  return (
      <img
      alt="AppIcon"
      src='/static/getAppOrange.png'
      {...props}
    />
  );
}

export default AppIcon;
