import React, {
  useRef,
  useState,
  useEffect,
  useCallback
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Users as UsersIcon } from 'react-feather';
import { useDispatch, useSelector } from 'src/store';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import useAuth from 'src/hooks/useAuth';
import { db } from 'src/lib/firebase';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  list: {
    padding: theme.spacing(1, 3)
  },
  listItemText: {
    marginRight: theme.spacing(1)
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  }
}));

const Contacts = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const isMountedRef = useIsMountedRef();
  const [isOpen, setOpen] = useState(false);
  const [userNetwork, setNetwork] = useState([]);

  const { user } = useAuth();

  const getContacts = useCallback(() => {
    let thisNet = [];
    let network = user.userNetwork;


    network.forEach(async (uuid) => {
      const netDoc = await db.doc(`/NetworkProfile/${uuid}`).get();
      const netContact = netDoc.data();


      thisNet = [...thisNet, netContact];

      if (isMountedRef.current) {

        setNetwork(thisNet);
      }
    })
  }, [isMountedRef])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

//  useEffect(() => {
//    getContacts();
//  }, [getContacts]);

  return (
    <>
      <Tooltip title="Network">
        <IconButton
          color="inherit"
          onClick={handleOpen}
          ref={ref}
        >
          <SvgIcon fontSize="small">
            <UsersIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >

        <Typography
          variant="h4"
          color="textPrimary"
        >
          Network
        </Typography>
        <Box mt={2}>
          <List disablePadding>
            {userNetwork.map((netUse) => {
              const contact = netUse;

              return (
                <ListItem
                  disableGutters
                  key={contact.uuid}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="Person"
                      src={contact.userProfileImageLink}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    disableTypography
                    primary={(
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        display="block"
                        underline="none"
                        noWrap
                        to="#"
                        variant="h6"
                      >
                        {contact.username}
                      </Link>
                    )}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default Contacts;
