import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
  TextField,
  Fab,
  CircularProgress
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 120,
    paddingBottom: 120,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    transformStyle: 'preserve-3d',
    //perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      //transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      //boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const Hero = ({ className, clickInvite, thisEmail, hasSubmitted,...rest }) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (event, value) => {
    setEmail(event.target.value);
  }

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    setLoading(true);
    let newEmail = email;
    console.log(newEmail);
    const payload = {
      email: newEmail,
      page: 'registerView'
    }
    console.log(payload);

    axios.defaults.baseURL = 'https://us-central1-see-hi.cloudfunctions.net/api';
    axios.post(`/TraksOS_Invite`, payload).then((res) => {
      console.log('response from sedgrid is ', res.data);
      enqueueSnackbar(`${email} Added to List`, {
        variant: 'success',
      });
      
      if(clickInvite) {
        setLoading(false);
        clickInvite(email);
      }

    })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      })
  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h2"
                color="secondary"
                style={{
                  fontWeight: 'lighter',
                  paddingBottom: 15
                }}
              >
                Introducing
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
                style={{
                  fontSize: 35
                }}
              >
                Traks: Secure A Spot
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  A professional business management software!
                  Developed with one goal in mind, help you build faster &amp; more scalable
                  processes. Spend less time adding data and more time working.
                  Each lead and job is fully customizable and easy to use.
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      10,000+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Clients Managed
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      UX
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Better. Easier
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      $1 Million+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Managed and Saved
                    </Typography>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Grid container
                    spacing={3}
                  >
                    {!hasSubmitted ? (
                      <Grid item>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          onChange={handleChange}
                          value={email}
                          variant="outlined"
                        />
                        <div className={classes.wrapper}>
                          <Button
                            onClick={handleSubmit}
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={loading}
                            style={{ marginTop: 15 }}
                          >Learn How</Button>
                          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </Grid>
                    ) : (
                        <Grid item>
                          <Typography
                            fullWidth
                            variant="h3"
                            style={{marginBottom: 25, color: 'green'}}
                          >
                            Email Sent to {thisEmail}
                          </Typography>
                          <Typography
                            fullWidth
                            color="textPrimary"
                            variant="h3"
                            >Please Check Your Junk Folder if you do not see communication</Typography>
                        </Grid>
                      )}
                    <Grid item>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
              item
              xs={12}
              md={7}
            >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                  
                />
              </div>

                <a href="https://www.traksos.com" className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/home/TraksOS_Pro_Guy.png"
                  href="https://www.traksos.com"
                />
                </a>

            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
