import React, {
    Component,
    useEffect,
    useState,
    useCallback
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {detect} from 'detect-browser';
import {
    makeStyles,
    useMediaQuery,
    useTheme,
    Dialog,
    DialogContent,
    Box,
    DialogActions,
    Button,
    Typography,
} from '@material-ui/core';
import AppIcon from 'src/components/AppIcon';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        backgroundColor: theme.palette.background.dark,
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    },
    logo: {
        marginRight: theme.spacing(2)
    },
}));

const GetApp = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [appModal, setModal] = useState(true);
    const [alreadyPressed, setPressed] = useState(false);

    let mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const isMountedRef = useIsMountedRef();

    const openMobileLink = useCallback(() => {
        if (isMountedRef.current) {
            const browser = detect();
            const operatingSystem = browser.os;
            if (operatingSystem === 'iOS' || operatingSystem === 'Android OS') {
                setModal(true);

            } else {
                setModal(false);

            }
        }
    }, [isMountedRef])

    const handleClose = useCallback(() => {
        if (isMountedRef.current) {
            setPressed(true)
            setModal(false);
        }
    }, [isMountedRef])

    useEffect(() => {
        if (!alreadyPressed) {
            openMobileLink();
        }
    });

    return (
            <Dialog
                open={appModal}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
            >
                <DialogContent
                className={classes.content}>
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center">
                        <Typography variant="h2">A Better Mobile Experience!</Typography>
                        <br />
                        <Typography variant="h4">Get Mobile Optimized TraksOS App</Typography>
                        <br />
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center">
                        <RouterLink to="/">
                            <AppIcon className={classes.logo} />
                        </RouterLink>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                    >No Thanks
              </Button>
                    <Button
                        color="secondary"
                        component="a"
                        href="https://join.traksos.com/GetApp"
                        variant="contained"
                    >Get App
              </Button>
                </DialogActions>
            </Dialog>

    );
};



export default GetApp;