import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const OnboardingGuard = ({ children }) => {
  const { isOnboarded, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  } else if (isOnboarded) {
    return <Redirect to="/app/account" />
  } else

  return (
    <>
      {children}
    </>
  );
};

OnboardingGuard.propTypes = {
  children: PropTypes.node
};

export default OnboardingGuard;