import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
    Box,
    Button,
    TextField,
    Typography,
    makeStyles,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        paddingTop: 128,
        paddingBottom: 128
    },
    browseButton: {
        marginLeft: theme.spacing(2)
    }
}));

const InviteConfirm = ({ className, inviteOpen, inviteDone, ...rest }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    
    const startOver = () => {
        if (inviteDone) {
            inviteDone()
        }
    }


    return (
        <Dialog
      open={inviteOpen}
      size="md"
      fullWidth>
        <DialogContent>
          <Box display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center">
            <Typography variant="h1">
              You've been Added!
                    </Typography>
                    <br/>
                    <br/>
            <img
              alt="EmailSent"
              src='/static/images/pic.png'
            />
            <br/>
                    <br/>
            
            <Typography variant="h3">
            Please check your Junk Folder to schedule your Onboarding!
              </Typography>
              <br/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
          variant="outlined"
          onClick={startOver}
          >All Done!</Button>

        </DialogActions>
      </Dialog>
    )

}

export default InviteConfirm;