import React from 'react'
import ReactPlayer from 'react-player'
import './responsive-player.css'

const VideoPlayer = ({link}) => {
    return (
        <div className="player-wrapper" alt="Invoicing App">
          <ReactPlayer url={link}
          className="react-player"
              //playing={true}
              controls={true}
              muted={false}
              width='100%'
              height='100%'
              />
        </div>
      );
}

export default VideoPlayer