export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: "AIzaSyCMzs2WM4B6Dy8EenVzFjCySjZ0WGv2BE8",
  authDomain: "see-hi.firebaseapp.com",
  databaseURL: "https://see-hi.firebaseio.com",
  projectId: "see-hi",
  storageBucket: "see-hi.appspot.com",
  messagingSenderId: "1006940724541",
  appId: "1:1006940724541:web:20fbf992d7706a16ed3954",
  measurementId: "G-96MYJW9MVT"
};
