import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'src/constants';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  const { user } = useAuth();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <Hidden mdDown>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            Version
            {' '}
            {APP_VERSION}
          </Typography>
        </Hidden>
        <Box flexGrow={1} />

        
        {user !== null ? (
          <div>
            <Button
              color="secondary"
              component={RouterLink}
              to="/app/account"
              variant="outlined"
              size="medium"
              className={classes.link}
            >
              Dashboard
          </Button>
          </div>
        ) : (
            <div>
              <Button
                component={RouterLink}
                to="/register"
                variant="outlined"
                size="medium"
                className={classes.link}
              >
                Sign Up
          </Button>
              <Button
                color="secondary"
                component={RouterLink}
                to="/app"
                variant="contained"
                size="medium"
                className={classes.link}
              >
                Login
          </Button>
            </div>
          )}
        <Divider className={classes.divider} />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

//Documentation link
//<Link
//  className={classes.link}
//  color="textSecondary"
//  component={RouterLink}
//  to="/docs"
//  underline="none"
//  variant="body2"
//>
//  Documentation
//        </Link>