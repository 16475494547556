import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import axios from 'axios';
import CTA from './CTA';
import FAQS from './FAQS';
import InviteConfirm from './InviteConfirm';
import { useSnackbar } from 'notistack';
import {analytics} from 'src/lib/firebase';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [inviteOpen, setInviteOpen] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [emailRegistered, setEmailRegistered] = useState('');
  const [loading, setLoading] = useState(false);


  const openInvite = (email) => {
    setLoading(true);

    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    const isValid = expression.test(String(email).toLowerCase());

    if (!isValid) {
      setLoading(false);
      return alert('Please Enter a Valid Email Address');
    }

    let newEmail = email;
    const payload = {
      email: newEmail,
      page: 'pageText'
    }
    console.log(payload);

    axios.defaults.baseURL = 'https://us-central1-see-hi.cloudfunctions.net/api';
    axios.post(`/TraksOS_Invite`, payload).then((res) => {
      console.log('response from sedgrid is ', res.data);
      if (res.data.error === undefined) {
        analytics.logEvent('invite_register');
        enqueueSnackbar(`${email} Added to List`, {
          variant: 'success',
        });

        setLoading(false);
        setInviteOpen(true);
        setSubmittedEmail(true);
        setEmailRegistered(email);
      } else {
        setLoading(false);
        alert(res.data.error);
      }
    })
      .catch((err) => {
        setLoading(false);
        alert(err);
      })
  }

  const inviteDone = (value) => {
    setInviteOpen(false);
  }

  const handleDoOver = () =>{
    setSubmittedEmail(false);
    setEmailRegistered('');
  }

  return (
    <Page
      className={classes.root}
      title="Invite"
    >

      <CTA clickInvite={openInvite} thisEmail={emailRegistered} hasSubmitted={submittedEmail} doOver={handleDoOver} loading={loading}/>
      <Hero clickInvite={openInvite} thisEmail={emailRegistered} hasSubmitted={submittedEmail} loading={loading}/>
      <Features />
      
      <FAQS />
      <InviteConfirm inviteOpen={inviteOpen} inviteDone={inviteDone} />
    </Page>
  );
};

export default HomeView;
