/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import moment from 'moment';
import {
  Link as LinkIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircle,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  Clipboard as Clipboard,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Zap as ZapIcon,
  Sun as SunIcon,
  FileText as FileTextIcon,
  PlusCircle as PlusIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

const differentSections = [
  {
    subheader: 'Pro Tools',
    items: [
//      {
  //      title: 'Dashboard',
    //    icon: PieChartIcon,
      //  href: '/app/reports/dashboard'
      //},
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative',
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Pro"
          />
        )
      },
      {
        title: 'Workforce',
        icon: CalendarIcon,
        href: '/app/management/labor_calendar',
        info: () => (
          <Chip
          color="secondary"
          size="small"
          label="Pro"
          />
        )
      },
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Open Leads List',
            href: '/app/management/customers'
          },
          {
            title: 'Open Lead Calendar',
            href: '/app/managment/customer_calendar'
          },
          {
            title: 'Client Archive',
            href: '/app/management/clientArchive'
          }
        ]
      },
      //{
      //  title: 'Estimates/Invoices',
      //  icon: ReceiptIcon,
      //  href: '/app/management/invoices',
      //  items: [
      //    {
      //      title: 'Invoices',
      //      href: '/app/management/invoices'
      //    },
      //    {
      //            title: 'View Invoice',
      //            href: '/app/management/invoices/1'
      //          }
      //        ]
      //      },
      {
        title: 'Trackers',
        icon: LinkIcon,
        href: '/app/management/trackers',
        items: [
          {
            title: 'Open Tracker List',
            href: '/app/management/trackers'
          },
          {
            title: 'Tracker Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Jobs',
        icon: BriefcaseIcon,
        href: '/app/management/jobs',
        items: [
          {
            title: 'Open Jobs List',
            href: '/app/management/jobs'
          },
          {
            title: 'Open Jobs Calendar',
            href: '/app/management/jobs_calendar'
          },
          {
            title: 'Jobs Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Tasks',
        icon: CheckCircle,
        href: '/app/management/myTasks',
        items: [
          {
            title: 'My Tasks',
            href: '/app/management/myTasks'
          }
        ]
      }
    ]
  },
//  {
//    subheader: 'Applications',
//    items: [
      
//      {
//        title: 'Networking',
//        href: '/app/social',
//        icon: ShareIcon,
//        items: [
//          {
//            title: 'Profile',
//            href: '/app/social/profile'
//          },
//          {
//            title: 'Feed',
//            href: '/app/social/feed'
//          }
//        ]
//      }
//    ]
//  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Add Company',
        href: '/app/add_company',
        icon: Clipboard
      },
      {
        title: 'Upgrade',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  
]

const sections = [
  {
    subheader: 'Pro Features',
    items: [
//      {
  //      title: 'Dashboard',
    //    icon: PieChartIcon,
      //  href: '/app/reports/dashboard'
      //},
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative',
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Pro"
          />
        )
      },
      {
        title: 'Workforce',
        icon: CalendarIcon,
        href: '/app/management/labor_calendar',
        info: () => (
          <Chip
          color="secondary"
          size="small"
          label="Pro"
          />
        )
      },
      {
        title: 'Weekly Reports',
        icon: FileTextIcon,
        href: '/app/reports/weekly_reports',
        info: () => (
          <Chip
          color="secondary"
          size="small"
          label="Pro"
          />
        )
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Open Leads List',
            href: '/app/management/customers'
          },
          {
            title: 'Open Lead Calendar',
            href: '/app/managment/customer_calendar'
          },
          {
            title: 'Client Archive',
            href: '/app/management/clientArchive'
          }
        ]
      },
      //{
      //  title: 'Estimates/Invoices',
      //  icon: ReceiptIcon,
      //  href: '/app/management/invoices',
      //  items: [
      //    {
      //      title: 'Invoices',
      //      href: '/app/management/invoices'
      //    },
      //    {
      //            title: 'View Invoice',
      //            href: '/app/management/invoices/1'
      //          }
      //        ]
      //      },
      {
        title: 'Trackers',
        icon: LinkIcon,
        href: '/app/management/trackers',
        items: [
          {
            title: 'Open Tracker List',
            href: '/app/management/trackers'
          },
          {
            title: 'Tracker Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Jobs',
        icon: BriefcaseIcon,
        href: '/app/management/jobs',
        items: [
          {
            title: 'Open Jobs List',
            href: '/app/management/jobs'
          },
          {
            title: 'Open Jobs Calendar',
            href: '/app/management/jobs_calendar'
          },
          {
            title: 'Jobs Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Tasks',
        icon: CheckCircle,
        href: '/app/management/orders',
        items: [
          {
            title: 'My Tasks',
            href: '/app/management/myTasks'
          },
          {
            title: 'Company Tasks',
            href: '/app/management/companyTasks'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Add Employees',
        href: '/app/company/add_employee',
        icon: PlusIcon,
      },
      {
        title: 'Manage Employees',
        href: '/app/company/companyNetwork',
        icon: UserPlusIcon,
        href: '/app/company/ManageEmployees'
      },
//      {
//        title: 'My Network',
//        href: "/app/myNetwork",
//        icon: UserPlusIcon,
//        items: [
//          {
//            title: 'My Network',
//            href: '/app/myNetwork'
//          },
//          {
//            title: 'Add To Network',
//            href: '/app/addToNetwork'
//          }
//        ]
//      }
    ]
  },



//  {
//    subheader: 'Applications',
//    items: [
//      
//      {
//        title: 'Networking',
  //        href: '/app/social',
  //        icon: ShareIcon,
  //        items: [
  //          {
  //            title: 'Profile',
  //            href: '/app/social/profile'
  //          },
  //          {
  //            title: 'Feed',
  //            href: '/app/social/feed'
  //          }
  //        ]
  //      }
  //    ]
  //  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Company',
        href: '/app/company',
        icon: Clipboard
      },
      {
        title: 'Upgrade',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
];

const LouveRoofSections = [
  {
    subheader: 'Pro Features',
    items: [
//      {
  //      title: 'Dashboard',
    //    icon: PieChartIcon,
      //  href: '/app/reports/dashboard'
      //},
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative',
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Pro"
          />
        )
      },
      {
        title: 'Workforce',
        icon: CalendarIcon,
        href: '/app/management/labor_calendar',
        info: () => (
          <Chip
          color="secondary"
          size="small"
          label="Pro"
          />
        )
      },
      {
        title: 'Weekly Reports',
        icon: FileTextIcon,
        href: '/app/reports/weekly_reports',
        info: () => (
          <Chip
          color="secondary"
          size="small"
          label="Pro"
          />
        )
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Open Leads List',
            href: '/app/management/customers'
          },
          {
            title: 'Open Lead Calendar',
            href: '/app/managment/customer_calendar'
          },
          {
            title: 'Client Archive',
            href: '/app/management/clientArchive'
          }
        ]
      },
      //{
      //  title: 'Estimates/Invoices',
      //  icon: ReceiptIcon,
      //  href: '/app/management/invoices',
      //  items: [
      //    {
      //      title: 'Invoices',
      //      href: '/app/management/invoices'
      //    },
      //    {
      //            title: 'View Invoice',
      //            href: '/app/management/invoices/1'
      //          }
      //        ]
      //      },
      {
        title: 'Trackers',
        icon: LinkIcon,
        href: '/app/management/trackers',
        items: [
          {
            title: 'Open Tracker List',
            href: '/app/management/trackers'
          },
          {
            title: 'Tracker Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Jobs',
        icon: BriefcaseIcon,
        href: '/app/management/jobs',
        items: [
          {
            title: 'Open Jobs List',
            href: '/app/management/jobs'
          },
          {
            title: 'Open Jobs Calendar',
            href: '/app/management/jobs_calendar'
          },
          {
            title: 'Jobs Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Tasks',
        icon: CheckCircle,
        href: '/app/management/orders',
        items: [
          {
            title: 'My Tasks',
            href: '/app/management/myTasks'
          },
          {
            title: 'Company Tasks',
            href: '/app/management/companyTasks'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Add Employees',
        href: '/app/company/add_employee',
        icon: PlusIcon,
      },
      {
        title: 'Manage Employees',
        href: '/app/company/companyNetwork',
        icon: UserPlusIcon,
        href: '/app/company/ManageEmployees'
      },
//      {
//        title: 'My Network',
//        href: "/app/myNetwork",
//        icon: UserPlusIcon,
//        items: [
//          {
//            title: 'My Network',
//            href: '/app/myNetwork'
//          },
//          {
//            title: 'Add To Network',
//            href: '/app/addToNetwork'
//          }
//        ]
//      }
    ]
  },



//  {
//    subheader: 'Applications',
//    items: [
//      
//      {
//        title: 'Networking',
  //        href: '/app/social',
  //        icon: ShareIcon,
  //        items: [
  //          {
  //            title: 'Profile',
  //            href: '/app/social/profile'
  //          },
  //          {
  //            title: 'Feed',
  //            href: '/app/social/feed'
  //          }
  //        ]
  //      }
  //    ]
  //  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Company',
        href: '/app/company',
        icon: Clipboard
      },
      {
        title: 'LouveRoof',
        href: '/app/louveroofDealer',
        icon: SunIcon
      },
    ]
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  const isPaid = user.userPaid ? true : false;

  const bottomRender = () => {
    const didPay = user.userPaid;
    const endDate = moment(user.userSubscriptionEnd);
    const difference = moment() - endDate
    if (didPay) {
      return 'Subscription Active'
    } else if (difference < 0) {
      return `Activate Pro`
    } else if (difference > 0) {
      return `Activate Pro`
    }
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
          </Box>
          <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          style={{
            marginTop: 10
          }}
          >
            <Link
            component={RouterLink}
            to={user.userPaid ? '/app/account' : '/pricing'}
            variant="h5"
            color="primary"
            underline="always"
            >
              {bottomRender()}
            </Link>
          </Box>
          {isPaid && (
            <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            style={{
              marginTop:10
            }}
            >
              <Button
              component={RouterLink}
              to='/app/referral_program'
              variant="contained"
              startIcon={<DollarSignIcon />}
              color="primary"
              >Referral Program</Button>
              </Box>
          )}
        </Box>
        <Divider />
        <Box p={2}>
          {user.owner !== user.companyUuid ? (
            <div>
              {differentSections.map((section) => (
                <List
                  key={section.subheader}
                  subheader={(
                    <ListSubheader
                      disableGutters
                      disableSticky
                    >
                      {section.subheader}
                    </ListSubheader>
                  )}
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname
                  })}
                </List>
              ))}
            </div>
          ) : user.louveRoofDealer ? (
            <div>
              {LouveRoofSections.map((section) => (
                <List
                  key={section.subheader}
                  subheader={(
                    <ListSubheader
                      disableGutters
                      disableSticky
                    >
                      {section.subheader}
                    </ListSubheader>
                  )}
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname
                  })}
                </List>
              ))}
            </div>
          ) :
              (
                <div>
                  {sections.map((section) => (
                    <List
                      key={section.subheader}
                      subheader={(
                        <ListSubheader
                          disableGutters
                          disableSticky
                        >
                        {section.subheader}
                      </ListSubheader>
                    )}
                  >
                    {renderNavItems({
                      items: section.items,
                      pathname: location.pathname
                    })}
                  </List>
                ))}
              </div>
            )}

        </Box>
        <Divider />

      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

//        <Box p={2}>
//<Box
//p={2}
//borderRadius="borderRadius"
//bgcolor="background.dark"
//>
//<Typography
//  variant="h6"
//  color="textPrimary"
//>
//  Need Help?
//</Typography>
//<Link
//  variant="subtitle1"
//  color="secondary"
//  component={RouterLink}
//  to="/docs"
//>
//  Check our Tutorials
//</Link>
//</Box>
//</Box>