import React, {useState} from 'react';
import { 
    Box,
    Grid,
    Typography,
    Container,
    makeStyles,
    Button,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
 } from '@material-ui/core';
import VideoPlayer from './VideoPlayer';
import Page from 'src/components/Page';
import Header from './Header';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
}));

const linkMap = [
    {
        title: 'My Account Settings',
        link: 'https://youtu.be/JsRP0Zpm-lg'
    },
    {
        title: 'Dashboard/Analytics',
        link: 'https://youtu.be/R2zO_yT2SKk'
    },
    {
        title: 'Weekly Reports',
        link: 'https://youtu.be/NFr92lFstG4'
    },
    {
        title: 'Workforce Calendar',
        link: 'https://youtu.be/qI-gg51zcGU'
    },
    {
        title: 'Add A New Lead',
        link: 'https://youtu.be/bPFPcE3lmHw'
    },
    {
        title: 'Customer Details Management',
        link: 'https://youtu.be/ZTg8FtklUdU'
    },
    {
        title: 'Customer Sold & New Jobs',
        link: 'https://youtu.be/ymVIb1dqqPM'
    },
    {
        title: 'Managing a Job or Service',
        link: 'https://youtu.be/tFnYc9EWWFY'
    },
    {
        title: 'Tasks and Completing Jobs',
        link: 'https://youtu.be/c6bfr19Gvhk'
    },
    {
        title: 'Adding/Using Tracking Links',
        link: 'https://youtu.be/Yn8kReuMYXk'
    },
    {
        title: 'Add & Manage Employees',
        link: 'https://youtu.be/0vbBlAJsF24'
    },
    {
        title: 'Company Settings',
        link: 'https://youtu.be/4NXzgtxufuw'
    },
    {
        title: 'Referral Program',
        link: 'https://youtu.be/cyBkAS0tLiQ'
    }
]

const HowToView = () => {

    const classes = useStyles()

    const [vidLink, setVidLink] = useState('https://youtu.be/R2zO_yT2SKk');
    const [loading, setLoading] = useState(false);

    const handleVidPic = (link) => {
        setVidLink(link)
    }
    
    return (

            <Container maxWidth="lg">
               
                <Box mt={3}>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12} md={3}>
                            <List
                                aria-label="videos-available"
                            >
                                {linkMap.map((lin, i) => 
                                <ListItem
                                key={i}
                                selected={vidLink === lin.link}
                                onClick = {() => handleVidPic(lin.link)}
                                button>
                                    <ListItemText
                                    primary={lin.title}
                                    primaryTypographyProps={{
                                        noWrap: false,
                                        variant: 'body1',
                                        color: 'textPrimary',
                                    }}
                                    />
                                </ListItem>
                                )}
                            </List>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            
                                
                            
                                <VideoPlayer link={vidLink} />
                            
                        </Grid>
                    </Grid>
                </Box>
            </Container>
    )
}

export default HowToView;