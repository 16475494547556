import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 120,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 0
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    //perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    //perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      //transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      //boxShadow: theme.shadows[0]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
      <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h2"
                color="secondary"
                style={{
                  fontWeight: 'lighter',
                  paddingBottom: 15
                }}
              >
                Introducing: TraksOS Pro
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
                style={{
                  fontSize: 35
                }}
              >
                Always Beat The Competition
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                >
                  Offer more to your Customers and 
                  Employees for LESS. A professional business management software,
                  developed with one goal in mind, help you build a faster &amp; more scalable
                  business. Spend less time adding data and analyzing, and more time working.
                  Each lead and job is fully customizable and easy to use.
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      10,000+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Clients Managed
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      UX
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Better. Easier
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      $1 Million+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Managed and Saved
                    </Typography>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Grid container
                  spacing={3}
                  >
                    <Grid item>
                      <Button
                      component={RouterLink}
                      to='/login'
                      variant="outlined"
                      >Log In</Button>
                    </Grid>
                    <Grid item>
                      <Button
                      component={RouterLink}
                      to='/register'
                      variant="outlined"
                      >Sign Up</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div>
              <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/home/TraksOS_Pro_Guy.png"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
