import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const CompanyGuard = ({ children }) => {
  const { user } = useAuth();

  if (user.companyUser) {
    return <Redirect to="/app/company" />;
  } else 
  return (
    <>
      {children}
    </>
  );
};

CompanyGuard.propTypes = {
  children: PropTypes.node
};

export default CompanyGuard;
