import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

import { db } from 'src/lib/firebase';
import moment from 'moment'

import useAuth from 'src/hooks/useAuth';

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      const { events } = action.payload;

      state.events = events;
    },
    createEvent(state, action) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    selectEvent(state, action) {
      const { eventId = null } = action.payload;


      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const reducer = slice.reducer;

function returnColor(status) {
  if (status === 'Appointment Set') {
      return '#008F51'
  } else if (status === 'Contacted') {
      return '#3D3FD2'
  } else if (status === 'Not Contacted') {
      return '#AB1C00'
  } else if (status === 'Not Qualified') {
      return '#929292'
  } else if (status === 'Open Lead') {
      return '#9F48C5'
  } else if (status === 'Pre-Qualified') {
      return '#FF7B00'
  } else if (status === 'Quoted') {
      return '#CFC120'
  }
}


export const getEvents = (permUser) => async (dispatch) => {

  const user = permUser;


  let allClients = []

  const clientDocs = await db.collection('Customers')
  .where('owner', '==', user.owner)
  .where('listOrganizer', '==', 'Open')
  .get();

  clientDocs.forEach((clientDoc) => {
    let customer = clientDoc.data();
    customer.appointmentTime = customer.appointmentTime.toDate()
    customer.appointmentTime = moment(customer.appointmentTime).toISOString()

    let salesName = customer.salesperson.trim();
    let firstName = salesName.split(' ')[0];

    let clientEvent = {
      id: customer.uuid,
      allDay: false,
      description: customer.productOfInterest,
      start: customer.appointmentTime,
      end: moment(customer.appointmentTime).add(2,'h').toISOString(),
      color: returnColor(customer.customerStatus),
      title: `${firstName}: ${customer.name}`
    }

    allClients = [clientEvent, ...allClients];
  })

  let events = {}
  events.events = allClients

  dispatch(slice.actions.getEvents(events));
};

export const createEvent = (data) => async (dispatch) => {
  const response = await axios.post('/api/calendar/events/new', data);

  dispatch(slice.actions.createEvent(response.data));
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (eventId, update) => async (dispatch) => {
  let clientId = eventId;
  let newStart = moment(update.start).toDate();

  let newUpdate = {
    appointmentTime: newStart
  };

  db.doc(`/Customers/${clientId}`).update(newUpdate);
  let response = await db.doc(`/Customers/${clientId}`).get();
  let customer = response.data();

  customer.appointmentTime = customer.appointmentTime.toDate()
  customer.appointmentTime = moment(customer.appointmentTime).toISOString()

  let salesName = customer.salesperson.trim();
  let firstName = salesName.split(' ')[0];

  let event = {
    id: customer.uuid,
    allDay: false,
    description: customer.productOfInterest,
    start: customer.appointmentTime,
    end: moment(customer.appointmentTime).add(2, 'h').toISOString(),
    color: returnColor(customer.customerStatus),
    title: `${firstName}: ${customer.name}`
  }



  dispatch(slice.actions.updateEvent(event));
};

export const deleteEvent = (eventId) => async (dispatch) => {
  await axios.post('/api/calendar/events/remove', {
    eventId
  });

  dispatch(slice.actions.deleteEvent({ eventId }));
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRange({
    start: start.getTime(),
    end: end.getTime()
  }));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
