import React, {
    useState,
    useRef
  } from 'react';
  import { capitalCase } from 'change-case';
  import {
    Badge,
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControlLabel,
    IconButton,
    Popover,
    SvgIcon,
    Switch,
    TextField,
    Tooltip,
    Typography,
    makeStyles,
    DialogActions
  } from '@material-ui/core';
  import {Youtube as Youtube} from 'react-feather';
  import { THEMES } from 'src/constants';
  import HowToView from 'src/views/howTo';
  
  const useStyles = makeStyles((theme) => ({
    badge: {
      height: 10,
      width: 10,
      borderRadius: 5,
      marginTop: 10,
      marginRight: 5
    },
    popover: {
      width: 320,
      padding: theme.spacing(2)
    }
  }));
  
  const HowTo = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    return (
        <>
            <Tooltip title="How To Videos">
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={handleOpen}
                    startIcon={<Youtube />}
                >How To Videos</Button>
            </Tooltip>
            <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
            >
                <DialogContent>
                    <HowToView />
                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    >Close</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default HowTo;
